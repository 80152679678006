// api/user_family_info
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl +'consume'; // Cambia esto si tu base URL es diferente

export const User_family_info = {

  obtenerTodos: () => {
    return axios.get(`${BASE_URL}/get.php?action=get_family_infos`);
  },

  obtenerPorId: (userId) => {
    return axios.post(`${BASE_URL}/get-by_id_all.php?action=get-by_id-family_infos`, userId);
  },
  
  enviarDatos: (nuevosDatos) => {
    return axios.post(`${BASE_URL}/family-post.php`, nuevosDatos);
  },
  eliminarPorId: (userId) => {
    return axios.post(`${BASE_URL}/delete.php?action=delete_by_id-family_infos`, userId);
  },

  obtenerPorName: (name) => {
    return axios.post(`${BASE_URL}/search_name.php?action=search_family_infos` , name);
  },
};
