// api/usuarios
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl +'consume'; // Cambia esto si tu base URL es diferente


export const Token_v = {

   // obtener  users por ID
   validate_token: (data) => {
    return axios.post(`${BASE_URL}/validate-token.php`, data);
  }

  
  

};