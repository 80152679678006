
// api/cis
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl +'consume'; // Cambia esto si tu base URL es diferente


export const DocumentsApi = {

  obtenerTodos: () => {
    return axios.get(`${BASE_URL}/get.php?action=get_documents`);
  },

  eliminarDocumento: async (documentId) => {
     return axios.post(`${BASE_URL}/delete.php?action=delete_by_id-docuemnts`, documentId);
  },

  obtenerPorName: (name) => {
    return axios.post(`${BASE_URL}/search_name.php?action=search_document`, name);
  },

  eliminarAlldocumentFile: (documentId) => {
    return axios.post(`${BASE_URL}/delete.php?action=delete_by_id-docuemnts`, documentId);
  },
  
  obtenerPorId: (userId) => {
    return axios.post(`${BASE_URL}/get-by_id_all.php?action=get_by_id-docuemnts`, userId);
  },

  enviarDatos: async (nuevosDatos) => {
      return axios.post(`${BASE_URL}/documents-post.php`, nuevosDatos);

  },

  obtenerDocumentosFile: (userId) => {
    return axios.get(`${BASE_URL}/file/${userId}/files`);
  },

  // Agrega más métodos aquí si es necesario
};