// api/consignments
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl + 'consume'; // Cambia esto si tu base URL es diferente

export const ConsignmentApi = {

  obtenerTodos: () => {
    return axios.get(`${BASE_URL}/get.php?action=get_consignments`);
  },

  obtenerPorId: (userId) => {
    return axios.post(`${BASE_URL}/get-by_id_all.php?action=get_by_id-consignments`, userId);
  },

  obtenerPorName: (name) => {
    return axios.post(`${BASE_URL}/search_name.php?action=search_name-consigment`, name);
  },


  enviarDatos: (nuevosDatos) => {
    return axios.post(`${BASE_URL}/consignments-post.php`, nuevosDatos);
  },

  eliminarPorId: (consignmentId) => {
   return axios.post(`${BASE_URL}/delete.php?action=delete_by_id-consignments`, consignmentId);
  },

  obtenerBancolombia: () => {
    return axios.get(`${BASE_URL}/get-suma.php?action=get-nancolombia`);
  },

  obtenerNequi: () => {
    return axios.get(`${BASE_URL}/get-suma.php?action=get-nequi`);
  },

  //monto por mes chart
  obtenerMontoporMes: async () => {
    return axios.get(`${BASE_URL}/get.php?action=get_consignments`);
  },
  

  
  // Agrega más métodos aquí si es necesario
};