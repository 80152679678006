import './whatssap.css'
import LogoWhatssap from '../../../assets/img/Whatssap.png'
function Whatssapcomponent() {
  return (
    <>

<a class="circle-content" href="https://api.whatsapp.com/send?phone=+573017254451&text=Hola,%20quisiera%20saber%20un%20poco%20más%20de%20a%20jesucristo%20ven%20sin%20tardar" target="_blank">
  <img src={LogoWhatssap}alt="Imagen"/>
</a>


    </>
  );
}

export default Whatssapcomponent;