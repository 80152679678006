// api/usuarios
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl +'consume'; // Cambia esto si tu base URL es diferente


export const UserApi = {

    // Nuevo método de inicio de sesión
  iniciarSesion: (credentials) => {
    const url = `${BASE_URL}/login.php`; // Ajusta la URL para iniciar sesión en tu API
    return axios.post(url, credentials);
  },

    // Método PUT para actualizar información de usuario
    actualizarUsuario: (userId) => {
      const url = `${BASE_URL}/perfil-post.php`; // Ajusta la URL para actualizar un usuario específico en tu API
      return axios.post(url, userId);
    },

  registrarUsuario: (userData) => {
    const url = `${BASE_URL}/register.php`; // Ajusta la URL de registro en tu API
    return axios.post(url, userData);
  },

  recuperarContrasena: (email) => {
    const url = `${BASE_URL}/login_restore-password.php`; // Cambia la URL según tu enpoint
    return axios.post(url, { email });
  },

  // obtener  users por ID
  ExistEmail: (email) => {
    return axios.post(`${BASE_URL}/get_exist-email.php`, email);
  },

  // obtener all users
  obtenerTodos: () => {
    return axios.get(`${BASE_URL}/get.php?action=get_all_users`);
  },

   // obtener  users por ID
   obtenePorID: (userId) => {
    return axios.post(`${BASE_URL}/get-by_id_all.php?action=get-by_id_perfil`, userId);
  },

   // obtener  users por ID
   obtenePorName: (Name) => {
    return axios.post(`${BASE_URL}/search_name.php?action=search_user`, Name);
  },

  changeRol: (userId) => {
    return axios.post(`${BASE_URL}/put-role.php`, userId);
  },
  
  

};