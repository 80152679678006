import React, { useEffect } from 'react';
//componentes
import NavbarAuth from "../../components/auth/NavbarAuth";
import SiderAuth from "../../components/auth/SiderAuth";
import HeaderStats from "../../components/auth/Headers/HeaderStats";
import UserPerfil from "../../components/auth/Perfil/UserPerfil";
import UploadConsignment from "../../components/auth/Upload/Cis/Consignment/Consigment";
import UploadDocument from "../../components/auth/Upload/Cis/Consignment/Documents/UploadDocuments";
import Medicina_info from "../../components/auth/Perfil/InformationUser/Medicina_info";
import Familiares_info from "../../components/auth/Perfil/InformationUser/Familiares_info";
import Referencias_info from "../../components/auth/Perfil/InformationUser/Referencias";
import AdminContent from "../../components/auth/Admin/AdminContent";
import HeaderNormal from "../../components/auth/Headers/HeaderNormal";
import Error404 from "../Error404";
import { Token_v } from "../../services/validate_token";
//library
import { useLocation } from 'react-router-dom';// Importa useLocation
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'

function Dashboard() {
  const location = useLocation(); // Obtiene la ruta actual

     //cookies
     const UserID = Cookies.get('User_id');
     const Cookietoken = Cookies.get('token');
     const Cookieemail = Cookies.get('email');

  // Función para renderizar el contenido en función de la ruta
  const renderContent = () => {

    //Consignacion
    if (location.pathname === "/dashboard/admin") {
      return <AdminContent />;
    }

    //Documentos
    else if (location.pathname === "/dashboard/uploadConsignment") {
      return <UploadConsignment />;
    } 

    //Documentos
    else if (location.pathname === "/dashboard/uploadDocuments") {
      return <UploadDocument />;
    } 
    //Perfil usuario
    else if (location.pathname === "/dashboard/Medicina_info") {
      return <Medicina_info />;
    }

    else if (location.pathname === "/dashboard/Familiares_info") {
      return <Familiares_info />;
    }

    else if (location.pathname === "/dashboard/Referencias_info") {
      return <Referencias_info />;
    }

    else if (location.pathname === "/dashboard/") {
      return <UserPerfil />;
    }
     else {
      return <Error404 />;
    }
  };

  //mostar componente en url especifica¿
  // Verificar si la ruta actual coincide con la URL en la que deseas mostrar HeaderStats
  const shouldShowHeaderStats = location.pathname === '/dashboard/admin';


  useEffect(() => {
    const validarToken = async () => {
      const formDataValidate = new FormData();
      formDataValidate.append('token', Cookietoken);
      formDataValidate.append('email', Cookieemail);

      try {
        const responseValidate = await Token_v.validate_token(formDataValidate);
        console.log("Token validate:", responseValidate.data.code);
        console.log("Token validate JSON:", JSON.stringify(responseValidate.data));

        if (responseValidate.data.code === "1") {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Oops, debes iniciar sesión nuevamente",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const cookiesToDelete = ['rol', 'User_id', 'name', 'email', 'phone', 'token'];

              for (let i = 0; i < cookiesToDelete.length; i++) {
                const cookieName = cookiesToDelete[i];
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
              }

              window.location.href = '#/Login';
            }
          });
        }
      } catch (error) {
        console.error('Error al validar el token:', error);
      }
    };

    validarToken(); // Ejecuta la función cuando se monta el componente
  }, []); // El segundo argumento es un arreglo de dependencias vacío para ejecutar esto solo una vez al montar el componente


  return (
    <>
      <SiderAuth></SiderAuth>
      <div className="relative md:ml-64 bg-blue-500">
        <NavbarAuth></NavbarAuth>
        {/* Header */}
       
        {shouldShowHeaderStats ? <HeaderStats /> : <HeaderNormal />}
        <div className="px-4 md:px-10 mx-auto w-full -m-24 bg-[#F1F5F9]">
          {/* Renderiza el contenido en función de la ruta */}
          {renderContent()}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
