//  /api/user_medicine_info
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl + 'consume';

export const User_contacto = {

  obtenerTodos: () => {
    return axios.get(`${BASE_URL}/get.php?action=get_contacts`);
  },

  obtenerPorId: (userId) => {
    return axios.post(`${BASE_URL}/get-by_id_all.php?action=get-by_id_contacts`, userId);
  },

  enviarDatos: (nuevosDatos) => {
    return axios.post(`${BASE_URL}/contacts-post.php`, nuevosDatos);
  },

  eliminarPorId: (userId) => {
    return axios.post(`${BASE_URL}/delete.php?action=delete_by_id-contacts`, userId);
  },

  obtenerPorName: (name) => {
    return axios.post(`${BASE_URL}/search_name.php?action=search_contact`, name);
  },

};
