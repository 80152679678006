//library
import React, { useState, useEffect } from "react";
import { UserApi } from "../../../services/UserApi";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Token_v } from "../../../services/validate_token";
// components
import Menu_perfil from "./Menu_perfil";

export default function PerfilSettings() {

  const navigate = useNavigate();
  const [Personals, setPersonal] = useState ([]);  

  //cookies
  const UserID = Cookies.get('User_id');
  const Cookietoken = Cookies.get('token');
  const Cookieemail = Cookies.get('email');
    
  const [formData, setFormData] = useState({
    
    name: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    phone: "",
    about_me: "",
    
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validar que todos los campos estén llenos
    for (const key in formData) {
      if (!formData[key]) {
        Swal.fire({
          icon: 'error',
          title: 'Campos vacíos',
          text: 'Por favor, complete todos los campos antes de guardar.',
        });
        return; // Detener el envío del formulario si hay campos vacíos
      }
    }
  
    // Mostrar SweetAlert de carga
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
    
         //data
    const formDataValidate = new FormData();
    formDataValidate.append('token', Cookietoken);
    formDataValidate.append('email', Cookieemail);
    //send data
    const responseValidate = await Token_v.validate_token(formDataValidate);

    console.log("Token validate:"+responseValidate.data.code);
    console.log("Token validate JSON: " + JSON.stringify(responseValidate.data));


    if (responseValidate.data.code === "1") {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Opps debe iniciar session nuevamente",
        showConfirmButton: true, // Muestra el botón "OK"
        allowOutsideClick: false, // Evita que el usuario haga clic fuera del cuadro de diálogo
      
      }).then((result) => {
        // Verifica si el usuario hizo clic en el botón "OK"
        if (result.isConfirmed) {
      // Array con los nombres de las cookies que deseas eliminar
       const cookiesToDelete = ['rol', 'User_id', 'name', 'email', 'phone', 'token'];

      // Itera sobre los nombres de las cookies y las elimina configurando su fecha de caducidad en el pasado
     for (let i = 0; i < cookiesToDelete.length; i++) {
     const cookieName = cookiesToDelete[i];
     document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
       }


        // Redirige al usuario a la URL deseada
          window.location.href = '#/Login';
  
        }
      
      });
        return;
    }




const formDatauser = new FormData();

formDatauser.append('id', UserID);
formDatauser.append('name', formData.name);
formDatauser.append('address', formData.address);
formDatauser.append('postal_code', formData.postal_code);
formDatauser.append('city', formData.city);
formDatauser.append('country', formData.country);
formDatauser.append('about_me', formData.about_me);
formDatauser.append('phone', formData.phone);

  
      // Utiliza Axios para enviar la solicitud PUT al servidor
      const response = await UserApi.actualizarUsuario(formDatauser);
  
      // Ocultar el SweetAlert de carga
      Swal.close();
  
      Swal.fire({
        icon: 'success',
        title: 'Datos enviados!',
        text: 'Los datos se han enviado correctamente.',
        confirmButtonText: 'OK',
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al enviar',
        text: 'Ocurrió un error al enviar los datos.',
      });
    }
  };
  
       //Consumir la PAi y traer datos
       useEffect(() => {
        const formData = new FormData();
        formData.append('id', UserID); // 'id' es el nombre del parámetro en form-data
      
        UserApi.obtenePorID(formData)
          .then(response => {
            if (response.data.code === '1') {
              console.log("Usuario no tiene personal id");
              setPersonal([]); // Establecer referencias_info como un array vacío
            } else {
              setPersonal(response.data.data);
            }
          })
          .catch(error => {
            console.error('Error al obtener data personal por usuario:', error);
          });

      }, [UserID]);
      
      


  return (
    <>

<Menu_perfil></Menu_perfil>

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Información personal</h6>
            <button
              className="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={handleSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            informacion del usuario
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Nombre y apellido Completo
                  </label>
                  <input
                   value={formData.name} 
                   onChange={handleInputChange}
                   id="name"
                    type="text"
                    placeholder={Personals.name}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 hidden">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Apellidos
                  </label>
                  <input
                   value="sdasdsa"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                 
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Contact Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Dirección
                  </label>
                  <input
                    placeholder={Personals.address}
                  value={formData.address}
                      onChange={handleInputChange}
                   id="address"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                 
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Ciudad
                  </label>
                  <input
                    placeholder={Personals.city}
                  value={formData.city}
                      onChange={handleInputChange}
                   id="city"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Pais
                  </label>
                  <input
                  value={formData.country}
                      onChange={handleInputChange}
                  id="country"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder={Personals.country}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Postal Code
                  </label>
                  <input
                  value={formData.postal_code}
                    onChange={handleInputChange}
                    id="postal_code"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder={Personals.postal_code}
                  />
                </div>
              </div>
            </div>

            <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   celular
                  </label>
                  <input
                  value={formData.phone}
                    onChange={handleInputChange}
                    id="phone"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder={Personals.phone}
                  />
                </div>
              </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              About Me
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    About me
                  </label>
                  <textarea
                  value={formData.about_me}
                      onChange={handleInputChange}
                  id="about_me"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder={Personals.about_me} 
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
