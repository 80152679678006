// api/user_healthy_conditions_info
import axios from 'axios';
import { Apiurl } from './Auth';
const BASE_URL = Apiurl +'consume'; // Cambia esto si tu base URL es diferente

export const User_healthy_conditions_info = {

  obtenerTodos: () => {
    return axios.get(`${BASE_URL}/get.php?action=get_healthy_conditions`);
  },

  obtenerPorName: (name) => {
    return axios.post(`${BASE_URL}/search_name.php?action=search_healthy`, name);
  },

  obtenerPorId: (userId) => {
    return axios.post(`${BASE_URL}/get-by_id_all.php?action=get-by_id_healthy`, userId);
  },
  
  enviarDatos: (nuevosDatos) => {
    return axios.post(`${BASE_URL}/healthy-post.php`, nuevosDatos);
  },

     // Método PUT para actualizar información de usuario
     actualizarUsuario: (userId, userData) => {
      const url = `${BASE_URL}/users/${userId}/healthy`; // Ajusta la URL para actualizar un usuario específico en tu API
      return axios.put(url, userData);
    },
};
