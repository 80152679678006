import LoginTem from "../components/Login&Register/LoginTem";

function Login() {
  return (
   <>
   <LoginTem></LoginTem>
   </>
  );
}

export default Login;