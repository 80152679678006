import TableConsigment from "./TableConsigment";

function UploadConsignment() {
  return (
    <>
    <TableConsigment />
    </>
  );
}

export default UploadConsignment;